.loading-spinner {
    position: fixed; /* Covers the viewport and stays in place during scrolling */
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; /* Full screen */
    background-color: rgba(0, 0, 0, 0.8); /* Black with 50% opacity */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* High z-index to ensure it appears above the content */
  }
  